* {
  font-family: 'Roboto', sans-serif;
  list-style-type: none;
  box-sizing: border-box;
}

* a{
  text-decoration: none !important;
  color: inherit !important;
}

.Xlogo { margin-left: 30px; }

@keyframes fadeInAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  65% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    transform: translateY(-5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


body {
  background-color: #fafafa !important;
}

body * {
  font-family: 'Montserrat', sans-serif;
}

h2 {
  font-weight: 400;
  font-size: 1.5rem;
}

h1, h2, h3, h4, h5, h6, p{
  margin: 0 !important;
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
}

header nav {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  animation: opacity ease 1s;
}

.search-input{ position: relative; }
.search-input form{ position: relative; }
.search-input input{ padding-left: 2rem; background-color: transparent;}
.search-icon { color: lightgrey; position: absolute; left: .625rem; top: 50%; transform: translateY(-50%); }

header nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

header nav ul li {
  position: relative;
  cursor: pointer;
  height: 1.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: lightgrey;
  text-transform: capitalize;
}

header nav ul li+li {
  margin-left: 2rem;
}

header nav ul li a {
  padding: 0 !important;
  outline: 0;
  border: 0;
  background: 0;
}

header nav ul li:after {
  content: "";
  width: calc(100% + 0.4rem);
  height: 2px;
  background: #959595;
  position: absolute;
  bottom: 10px;
  left: -0.2rem;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition: all 0.3s ease;
  z-index: 999;
}

header nav ul li.active {
  color: #353535;
}

header .header-logo span{
  line-height: 1;
  font-size: 12px;
  margin-left: -.5rem;
}
.hidden{ display: none; }
header nav ul li.active:after {
  opacity: 1;
  filter: alpha(opacity=15);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
  bottom: 0px;
}

ul.clients-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  padding: 0;
  margin: 0;
}

li.clients {
  position: relative;
  transition: all 0.5s ease-out;
  filter: none;
  overflow: hidden;
  animation: fadeInAnimation ease 1s;
  animation-fill-mode: forwards;
  margin: 0.1rem;
}

li.clients+.clients {
  margin: 0.1rem;
}

li.clients .client-names:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.35;
  filter: alpha(opacity=50);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  -ms-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}

li.clients:hover .client-names:after {
  background: white;
  opacity: 0;
  filter: alpha(opacity=50);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

li.clients h2 {
  z-index: 999;
  color: white;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease;margin: 0;
  margin: 0;
  animation: fadeInAnimation ease 1s;
  animation-fill-mode: forwards;
}

li.clients a:hover>h2 {
  opacity: 0;
}

li.clients a {
  display: flex;
  justify-content: center;
  align-items: center;
}

li.clients img {
  display: block;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all 1s ease;
}

li.clients:hover>img {
  transform: scale(1.2);
}

li.clients:hover .href-btns {
  bottom: 5%;
}

li.clients:hover .href-btns a{
  font-size: 1rem;
  padding: 1rem;
  background-color: white;
}

li.clients .href-btns {
  z-index: 999;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: -15%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease;
  margin: 0;
}

li.clients .href-btns a{
  height: 2rem;
  background-color: rgb(126, 126, 126);
  border-radius: 1rem;
  width: fit-content;
  opacity: 0.95;
  padding: .5rem; 
  font-size: 0.5rem;
  transition: all 0.5s ease;
}

li.clients .href-btns a+a{
  margin-left: 1rem;
}

footer {
  background: #151515;
  border-color: #353535;
  color: rgb(255, 255, 255, 0.8);
  padding: 1.5rem 0;
  letter-spacing: 0.03em;
}

footer p {
  margin: 0;
}

footer .part-1 {
  padding: 2rem 0;
  font-size: .9rem;
  font-weight: 300;
  border-bottom: 1px solid rgba(250, 250, 250, .10);
}

footer .part-1 img {
  margin-bottom: 1rem;
}

footer .part-2 {
  padding: 2rem 0;
  font-size: .7rem;
  font-weight: 300;
}

footer .part-2 a {
  margin-left: 0.25rem;
  cursor: pointer;
  font-weight: 400;
  color: #fed009;
}

.client-names{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 998;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-detail-page{
  width: 100%;
  height: 100%;
  background-color:#f4f4f4;
  color: #757575;
  animation: opacity ease 1s;

}

.project-detail-page .prev-next{
  color: #959595;
  width: 100%;
}

.project-detail-page .prev-next a{
  display: flex;
  width: fit-content;
  flex-direction: row;
  font-size: 1.25rem;
  align-items: center;
}

.project-detail-page .prev-next a *{
  line-height: 1rem;
}

.project-detail-page .prev-next h6{
  font-size: 0.8rem;
  font-weight: 700;
}

.project-detail-page .project-detail {
  background-color: white;
  -webkit-box-shadow: 0px 3px 3px 1px rgb(100 100 100 / 10%);
  -moz-box-shadow: 0px 3px 3px 1px rgb(100 100 100 / 10%);
  box-shadow: 0px 3px 3px 1px rgb(100 100 100 / 10%);
}

.project-detail-page .project-detail .detail-info{
  padding: 2rem;
  text-align: start;
}


.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.project-detail-page .project-detail .detail-info h1,
.project-detail-page .other-projects .card-title .h5{
  line-height: 0.85;
  margin-bottom: 0.25rem !important;
}

.project-detail-page .project-detail .detail-info h1{
  font-size: 1.6rem;
  font-weight: 900;
  color: #353535;
  letter-spacing: -0.02rem;
}

.project-detail-page .project-detail .detail-info h3{
  font-size: 1rem;
  font-weight: 600;
  color: #858585;
  margin-bottom: 0.35rem !important;
}

.project-detail-page .project-detail .detail-info h3 b{
  font-weight: 700;
}

.project-detail-page .project-detail .detail-info .subtitle{
  width: 40%;
}

.project-detail-page .project-detail .detail-info .subtitle h3{
  color: #959595;
  border-bottom: 2px solid #858585;
  line-height: 1.5;
}

.project-detail-page .project-detail .detail-info .subtitle h3{
}


.project-detail-page .project-detail .detail-info h4.kategori,
.project-detail-page .other-projects .card-text .kategori{
  color: #b9b9b9;
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 0.5rem !important;
}

.project-detail-page .other-projects .other-project-title h2{ 
  font-size: 1.4rem;
  font-weight: 600;
  color: #858585;
  margin-bottom: 0.35rem !important;
}


.project-detail-page .project-detail .detail-info p{
  font-size: 0.85rem;
  font-weight: 600;
  color: #757575;
}

.project-detail-page .project-detail .detail-img img{
  width: 100%;
}

.other-projects .card{
  -webkit-box-shadow: 0px 3px 3px 1px rgb(100 100 100 / 10%);
  -moz-box-shadow: 0px 3px 3px 1px rgb(100 100 100 / 10%);
  box-shadow: 0px 3px 3px 1px rgb(100 100 100 / 10%);
  border-radius: 0;
}

.react-slideshow-container .default-nav {
  padding: 0 !important;
  border-radius: 0 !important;
}


@media only screen and (max-width: 1280px) {

.project-detail-page .project-detail {
  flex-direction: column-reverse !important;
}

.project-detail-page .project-detail .detail-img,
.project-detail-page .project-detail .detail-info{
  width: 100%;
}

span.search-input form{
  position: relative;
}

.search-input input{
  transition: .5s all ease;
}

.search-icon {
  left: .725rem;
}

span.search-input input ~ .search-icon{

}

  header nav ul {
    justify-content: end;
  }

  header nav ul li {
    height: 1.5rem;
    ;
  }

  header nav ul li+li {
    margin-left: 1rem;
  }

}

@media only screen and (max-width: 768px) {
  .project-detail-page.p-5{
    padding: 0.1rem !important;
    padding-top: 2rem !important;
  }

  .form-control {
    background-color: transparent;
  }

  .other-projects{
    margin-bottom: 2rem;
  }

  header nav {
    flex-direction: column;
    padding: 0rem;
  }

  header nav > span {
    padding: 0.95rem 0 0.75rem 0 !important;
  }

  header nav ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 1.25rem;
  }

  header nav ul li {
    font-size: 0.75rem;
    height: 1.35rem;
  }

  li.clients {
    margin: 0 0.1rem;
  }

  header nav ul li+li {
    margin-left: 1rem;
  }
}